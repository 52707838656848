var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"pageTable"}},[(_vm.rol != 'root' && _vm.rol != 'admin' && _vm.rol != 'admin-empresa')?_c('v-container',{staticClass:"d-flex align-center justify-center",staticStyle:{"height":"80vh","width":"80vw"}},[_c('v-card',{staticStyle:{"border-radius":"20px"},attrs:{"max-width":"500"}},[_c('v-card-title',{staticClass:"justify-center card_titulo"},[_c('div',{staticClass:"headerModal"},[_c('img',{staticClass:"imgModal",attrs:{"src":'/static/icon/peligro.svg'}})])]),_c('v-card-text',{staticClass:"titleModal text-center"},[_vm._v(" Esta vista no esta disponible para este rol de usuario. ")]),_c('v-card-actions',{staticClass:"d-flex align-center justify-center card_accion"},[_c('v-btn',{staticClass:"btnGuardar",on:{"click":function($event){return _vm.redireccionar()}}},[_vm._v(" Ok ")])],1)],1)],1):_c('v-container',{attrs:{"xl":"","fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('data-table',{ref:"tabla",attrs:{"tableName":'Tipos de incidencias',"columns":_vm.columns,"items":_vm.tableData.data,"paginationInfo":_vm.tableData.paginatorInfo,"showAdvancedFilters":true,"filters":_vm.filters,"perPage":[10,25,50,100],"loading":_vm.isLoading},on:{"pagination":_vm.getPaginationInfo,"setFilters":_vm.setFilters},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return _c('tbody',{},_vm._l((data),function(item){return _c('tr',{key:item.id},[_c('td',[(item.cliente == null)?_c('div',[_vm._v(" General ")]):_c('div',[_vm._v(" "+_vm._s(item.cliente.nombre)+" ")]),(item.empresa == null)?_c('div',{staticClass:"tblSecundario"},[_vm._v(" Incidencia General ")]):_c('div',{staticClass:"tblSecundario"},[_vm._v(" "+_vm._s(item.empresa.nombre)+" ")])]),_c('td',[_c('div',[_vm._v(_vm._s(item.nombre))])]),_c('td',[_c('div',{staticClass:"tblCenter"},[_c('v-chip',{staticClass:"ma-2 tblPrincipal",staticStyle:{"width":"100px"},attrs:{"color":_vm.obtenerColor(item.color_id)}})],1)]),_c('td',[_c('div',{staticClass:"tblOpciones"},[(
                                                    item.valor !== 'tipo_incidencia_vacaciones' &&
                                                    item.valor !== 'tipo_incidencia_retardo_justificado' &&
                                                    item.valor !== 'tipo_incidencia_incapacidad')?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"botonHover",attrs:{"icon":"","fab":"","dark":"","small":""},on:{"click":function($event){return _vm.abrirModal('update', item)}}},on),[_c('v-icon',{staticClass:"tamIconoBoton invertirColorBotones"},[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"botonHover",attrs:{"icon":"","fab":"","dark":"","small":""},on:{"click":function($event){return _vm.eliminar(item)}}},on),[_c('v-icon',{staticClass:"tamIconoBoton invertirColorBotonesDelete"},[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])]:_vm._e()],2)])])}),0)}}])},[_c('template',{slot:"actionButtonsLeft"},[_c('div',{staticClass:"botonAgregar"},[_c('v-btn',{staticClass:"btnAdd",attrs:{"rounded":"","color":"#004BAF","right":""},on:{"click":function($event){return _vm.abrirModal('add')}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"dark":""}},[_vm._v("add")]),_vm._v("Agregar "),_c('div',{staticClass:"mr-4"})],1)],1)]),_c('template',{slot:"filters"},[_c('v-col',{staticClass:"py-0",attrs:{"xs":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"outlined":"","label":"Nombre"},model:{value:(_vm.nombre),callback:function ($$v) {_vm.nombre=$$v},expression:"nombre"}})],1),(_vm.rol == 'root')?_c('v-col',{staticClass:"py-0",attrs:{"xs":"12","sm":"6","md":"6"}},[_c('v-autocomplete',{attrs:{"outlined":"","items":_vm.clientes,"item-text":"nombre","item-value":"id","label":"Cliente","persistent-hint":""},model:{value:(_vm.cliente_value),callback:function ($$v) {_vm.cliente_value=$$v},expression:"cliente_value"}})],1):_vm._e(),(_vm.rol == 'root' || _vm.rol == 'admin')?_c('v-col',{staticClass:"py-0",attrs:{"xs":"12","sm":"6","md":"6"}},[_c('v-autocomplete',{attrs:{"outlined":"","items":_vm.empresas,"item-text":"nombre","item-value":"id","label":"Empresa","persistent-hint":""},model:{value:(_vm.empresa_value),callback:function ($$v) {_vm.empresa_value=$$v},expression:"empresa_value"}})],1):_vm._e()],1)],2)],1)],1),[_c('v-dialog',{staticClass:"borde-card",attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"botonModal",attrs:{"id":"btnModal"}},on))]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"borde-card"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                    var invalid = ref.invalid;
return [_c('v-card-title',{staticClass:"justify-center card_titulo"},[_c('div',{staticClass:"headerModal"},[_c('img',{staticClass:"imgModal",attrs:{"src":_vm.imagen}}),_c('h2',{staticClass:"titleModal"},[_vm._v(_vm._s(_vm.tituloModal))])])]),_c('v-card-text',{staticClass:"card_texto"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoadingModal),expression:"isLoadingModal"}],attrs:{"id":"padre"}},[_c('div',{attrs:{"id":"loading"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":100,"width":7,"color":"blue"}})],1)]),_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoadingModal),expression:"!isLoadingModal"}],attrs:{"grid-list-md":"","id":"contenedor"}},[_c('v-divider',{staticClass:"divider"}),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                    var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"Nombre","error-messages":errors,"required":""},model:{value:(_vm.incidencia.nombre),callback:function ($$v) {_vm.$set(_vm.incidencia, "nombre", $$v)},expression:"incidencia.nombre"}})]}}],null,true)})],1),[_c('v-col',{staticClass:"py-0",attrs:{"xs":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Color","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                    var errors = ref.errors;
return [_c('v-select',{attrs:{"outlined":"","items":_vm.colores,"item-text":"nombre","item-value":"id","label":"Color","persistent-hint":"","error-messages":errors},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',{staticClass:"chip--select-multi",staticStyle:{"width":"100px"},attrs:{"input-value":data.selected,"color":data.item.nombre}})]}},{key:"item",fn:function(data){return [(_vm.validateObj(data))?[_c('div',[_vm._v(_vm._s(data.item))])]:[_c('v-chip',{staticClass:"chip--select-multi",staticStyle:{"width":"100px"},attrs:{"input-value":data.selected,"color":data.item.nombre}})]]}}],null,true),model:{value:(_vm.incidencia.color_id),callback:function ($$v) {_vm.$set(_vm.incidencia, "color_id", $$v)},expression:"incidencia.color_id"}})]}}],null,true)})],1)],(_vm.rol == 'root')?[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Cliente","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                    var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"Cliente","items":_vm.clientes,"item-text":"nombre","item-value":"id","persistent-hint":"","required":"","error-messages":errors},model:{value:(_vm.cliente_value),callback:function ($$v) {_vm.cliente_value=$$v},expression:"cliente_value"}})]}}],null,true)})],1)]:_vm._e(),(_vm.rol == 'root' || _vm.rol == 'admin')?[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","xs":"12","sm":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Empresa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                    var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"Empresa","items":_vm.empresas,"item-text":"nombre","item-value":"id","persistent-hint":"","required":"","error-messages":errors},model:{value:(_vm.empresa_value),callback:function ($$v) {_vm.empresa_value=$$v},expression:"empresa_value"}})]}}],null,true)})],1)]:_vm._e()],2)],1)],1),_c('v-card-actions',{staticClass:"d-flex justify-end card_accion"},[_c('button',{staticClass:"btnCerrar",attrs:{"disabled":_vm.isSaving},on:{"click":function($event){return _vm.cerrarModal()}}},[_vm._v(" Cancelar ")]),_c('v-btn',{staticClass:"btnGuardar",attrs:{"disabled":invalid,"loading":_vm.isSaving},on:{"click":function($event){return _vm.guardar()}}},[_vm._v(" Guardar ")])],1)]}}])})],1)],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }